<script>
  import { defineComponent, ref } from "@vue/composition-api";

  import { resolveOrganizationLogo } from "@/composables/useOrganizations";

  export default defineComponent({
    props: {
      contractorStats: {
        type: Array,
        required: false
      },
      icons: {
        type: Object,
        required: true
      }
    },
    setup() {
      const menu = ref(false);

      return {
        menu,
        resolveOrganizationLogo
      };
    }
  });
</script>
<template>
  <div>
    <v-card-title class="align-start">
      <span class="subtitle-1 font-weight-semibold align-middle">
        Contractors on Site
      </span>
    </v-card-title>
    <v-card-text v-if="contractorStats.length > 0">
      <v-row>
        <v-col
          cols="6"
          v-for="contractor in contractorStats"
          :key="contractor.fulcrum_id"
        >
          <v-card outlined elevation="5">
            <div class="d-flex justify-space-between pa-4">
              <!-- Contractor Name -->
              <div class="d-flex align-center">
                <v-avatar size="30" rounded class="ma-2">
                  <v-img
                    :src="resolveOrganizationLogo(contractor.contractor_record)"
                  ></v-img>
                </v-avatar>
                <div class="font-weight-semibold">
                  {{ contractor.contractor_name }}
                </div>
              </div>

              <!-- Comment -->
              <div class="d-flex align-center">
                <div class="ml-5">
                  <v-menu
                    v-model="contractor.menu"
                    :key="contractor.contractor_fulcrum_id"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="contractor.pers_equip_comment"
                        left
                        color="info"
                        size="26"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiCommentEyeOutline }}
                      </v-icon>
                    </template>
                    <v-card outlined max-width="400">
                      <v-card-title class="subtitle-1 font-weight-medium">
                        <v-icon left color="info">
                          {{ icons.mdiCommentTextOutline }}
                        </v-icon>
                        Contractor Comment
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>{{
                        contractor.pers_equip_comment
                      }}</v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>

              <!-- Personnel Count -->
              <div class="d-flex align-center">
                <v-icon left size="26">
                  {{ icons.mdiAccountHardHat }}
                </v-icon>
                <span class="font-weight-bold">
                  {{ contractor.personnel_count }}
                </span>
              </div>

              <!-- Equipment Count -->
              <div class="d-flex align-center">
                <div class="ml-4">
                  <v-icon left size="26">
                    {{ icons.mdiDumpTruck }}
                  </v-icon>
                  <span class="font-weight-bold">{{
                    contractor.equipment_count
                  }}</span>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <div class="subtitle-2 font-weight-medium">
        No contractors on site today.
      </div>
    </v-card-text>
  </div>
</template>
