<script>
  import { defineComponent } from "vue-demi";

  export default defineComponent({
    props: {
      personnelItems: {
        type: Array,
        required: false
      }
    },

    setup() {
      return {};
    }
  });
</script>
<template>
  <v-card flat>
    <v-card-title> Personnel On Site Today </v-card-title>
    <v-card-text>
      <v-data-iterator
        :items="personnelItems"
        item-key="contractor_name"
        :items-per-page="9"
        single-expand
        hide-default-footer
      >
        <template v-slot:default="{ items }">
          <v-row>
            <v-col
              v-for="item in items"
              :key="item.contractor_name"
              cols="12"
              sm="12"
              lg="4"
            >
              <v-card outlined elevation="5">
                <v-card-title
                  ><h6>{{ item.contractor_name }}</h6></v-card-title
                >
                <v-divider></v-divider>
                <v-list>
                  <v-list-item v-if="item.foreman_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Foremen:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.foreman_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.journeyman_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Journeymen:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.journeyman_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.electrician_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Electricians:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.electrician_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.operator_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Operators:</v-list-item-content
                    >
                    <v-list-item-content
                      class="fd-flex justify-space-around align-end font-weight-medium"
                      >{{ item.operator_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.masonry_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Masons:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.masonry_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.skilled_laborer_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Skilled Laborers:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      right
                      >{{ item.skilled_laborer_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.unskilled_laborer_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Unskilled Laborers:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.unskilled_laborer_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.flagger_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Flaggers:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.flagger_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.traffic_officer_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Traffic Officers:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.traffic_officer_qty }}</v-list-item-content
                    >
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>
  </v-card>
</template>
