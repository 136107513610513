<script>
  import {
    mdiAccountHardHat,
    mdiClipboardCheckOutline,
    mdiClipboardPulseOutline,
    mdiDumpTruck,
    mdiImageMultipleOutline
  } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";

  // Components
  import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs.vue";
  import { useDailyLogViewBreadcrumbs } from "@/components/common/breadcrumbs/useBreadcrumbs";
  import DailyLogTabEquipment from "@/views/apps/daily-log/daily-log-view/daily-log-tab-equipment/DailyLogTabEquipment.vue";
  import DailyLogTabItemTracker from "@/views/apps/daily-log/daily-log-view/daily-log-tab-item-tracker/DailyLogTabItemTracker.vue";
  import DailyLogTabOverview from "@/views/apps/daily-log/daily-log-view/daily-log-tab-overview/DailyLogTabOverview.vue";
  import DailyLogTabPersonnel from "@/views/apps/daily-log/daily-log-view/daily-log-tab-personnel/DailyLogTabPersonnel.vue";
  import DailyLogTabPhotos from "@/views/apps/daily-log/daily-log-view/daily-log-tab-photos/DailyLogTabPhotos.vue";

  import { useIdr } from "../useIdr";
  import DailyLogInfoPanel from "./daily-log-info-panel/DailyLogInfoPanel.vue";
  //import DailyLogItemsPanel from "./daily-log-items-panel/DailyLogItemsPanel.vue";

  export default defineComponent({
    name: "apps-daily-log-view",
    components: {
      Breadcrumbs,
      DailyLogInfoPanel,
      DailyLogTabOverview,
      DailyLogTabPhotos,
      DailyLogTabItemTracker,
      DailyLogTabPersonnel,
      DailyLogTabEquipment
      // DailyLogDelayAlert,
      // DailyLogWeatherPanel,

      // DailyLogItemsPanel
    },
    setup() {
      const { dailyLog, dailyLogHasLoaded } = useIdr();
      const dailyLogTab = ref(null);
      const tabs = [
        { icon: mdiClipboardCheckOutline, title: "Overview" },
        { icon: mdiImageMultipleOutline, title: "Photos" },
        { icon: mdiClipboardPulseOutline, title: "Item Tracker" },
        { icon: mdiAccountHardHat, title: "Personnel" },
        { icon: mdiDumpTruck, title: "Equipment" }
      ];
      const { breadcrumbs } = useDailyLogViewBreadcrumbs(dailyLog);

      return {
        dailyLog,
        dailyLogHasLoaded,
        dailyLogTab,
        tabs,
        breadcrumbs,
        icons: {
          mdiAccountHardHat,
          mdiClipboardPulseOutline,
          mdiImageMultipleOutline,
          mdiClipboardCheckOutline
        }
      };
    }
  });
</script>

<template>
  <div v-if="dailyLogHasLoaded" id="user-view">
    <breadcrumbs
      class="mb-5"
      :items="breadcrumbs"
      :active-link="{
        text: `${dailyLog.idr_date}`,
        disabled: true,
        href: `/apps/project/${dailyLog.project_id}/construction-record/${dailyLog.const_id}/idr/view/${dailyLog.fulcrum_id}`
      }"
    />
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <daily-log-info-panel
          v-if="dailyLogHasLoaded"
          :daily-log-data="dailyLog"
        ></daily-log-info-panel>
      </v-col>
      <v-col cols="12" md="8">
        <v-tabs v-model="dailyLogTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          id="user-tabs-content"
          v-model="dailyLogTab"
          class="mt-5 pa-1"
        >
          <!-- Daily Log Overview Tab -->
          <v-tab-item>
            <daily-log-tab-overview
              :daily-log-data="dailyLog"
            ></daily-log-tab-overview>
          </v-tab-item>

          <!-- Photos Tab -->
          <v-tab-item>
            <daily-log-tab-photos
              :photos="dailyLog.idr_photo"
            ></daily-log-tab-photos>
          </v-tab-item>

          <!-- Item Tracker Tab -->
          <v-tab-item>
            <daily-log-tab-item-tracker
              :logged-items="dailyLog.logged_items"
            ></daily-log-tab-item-tracker>
          </v-tab-item>

          <!-- Personnel Tab -->
          <v-tab-item>
            <daily-log-tab-personnel
              :personnel-items="dailyLog.personnel_items"
            ></daily-log-tab-personnel>
          </v-tab-item>

          <!-- Equipment Tab -->
          <v-tab-item>
            <daily-log-tab-equipment
              :equipment-items="dailyLog.equipment_items"
            ></daily-log-tab-equipment>
          </v-tab-item>
        </v-tabs-items>
        <!-- <v-row
          v-if="
            dailyLog.delay_duration ||
            dailyLog.delay_reason ||
            dailyLog.delay_reason_other
          "
        >
          <v-col cols="12">
            <daily-log-delay-alert
              :delay-duration="dailyLog.delay_duration"
              :delay-reason="dailyLog.delay_reason"
              :delay-reason-other="dailyLog.delay_reason_other"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <daily-log-weather-panel
              v-if="
                dailyLog.weather ||
                dailyLog.temperature ||
                dailyLog.wthr_comment
              "
              :weather="dailyLog.weather"
              :temperature="dailyLog.temperature"
              :weather-comment="dailyLog.wthr_comment"
            />
          </v-col>
          <v-col cols="12" md="6">
            <daily-log-photo-panel :photos="dailyLog.idr_photo" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <daily-log-items-panel :logged-items="dailyLog.logged_items" />
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
  </div>
</template>
