<script>
  import {
    mdiAccountHardHatOutline,
    mdiClockEnd,
    mdiClockStart
  } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { resolveOrganizationLogo } from "@/composables/useOrganizations";
  import { avatarText, formatDate } from "@/helpers/filter";

  export default defineComponent({
    props: {
      dailyLogData: {
        type: Object,
        required: true
      }
    },
    setup() {
      return {
        avatarText,
        formatDate,
        resolveOrganizationLogo,
        icons: {
          mdiAccountHardHatOutline,
          mdiClockStart,
          mdiClockEnd
        }
      };
    }
  });
</script>

<template>
  <v-row class="project-info-panel">
    <!-- idr summary -->
    <v-col cols="12">
      <v-card flat class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="dailyLogData.inspector_full_name ? '' : 'primary'"
            :class="
              dailyLogData.inspector_full_name
                ? ''
                : 'v-avatar-light-bg primary--text'
            "
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="dailyLogData.inspector_org_record"
              :src="resolveOrganizationLogo(dailyLogData.inspector_org_record)"
            ></v-img>
            <span v-else class="font-weight-semibold text-5xl">{{
              avatarText(dailyLogData.inspector_full_name)
            }}</span>
          </v-avatar>
          <!-- <span class="my-2 primary--text">{{ projectData.proceed_num }}</span> -->
          <!-- <span class="mb-2 text-wrap">{{ projectData.proceed_name }}</span> -->
          <v-chip
            v-if="dailyLogData.const_type"
            label
            :class="`v-chip-light-bg text-sm font-weight-semibold my-2`"
          >
            {{ dailyLogData.const_type }}
          </v-chip>
        </v-card-title>

        <!-- Details -->
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-0">Details</h2>

          <v-divider></v-divider>

          <v-list>
            <!-- inspector name -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiAccountHardHatOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Inspector: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ dailyLogData.inspector_full_name }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- start time -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiClockStart }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Start Time: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ dailyLogData.start_time }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- end time -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiClockEnd }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> End Time: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ dailyLogData.end_time }}
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <!-- Comment -->
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-0">Comment</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-3">
              <span class="font-weight-medium">
                {{ dailyLogData.idr_comment }}
              </span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
