<script>
  import { mdiClose } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { useIdr } from "@/views/apps/daily-log/useIdr";
  export default defineComponent({
    props: {
      photos: {
        type: [Array, String],
        required: false
      }
    },
    setup() {
      const { overlay, resolveThumbnailPhotoById, resolveLargePhotoById } =
        useIdr();

      return {
        overlay,
        resolveThumbnailPhotoById,
        resolveLargePhotoById,
        icons: {
          mdiClose
        }
      };
    }
  });
</script>

<template>
  <v-card flat>
    <v-card-title class="align-start">
      <span>Photos</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text v-if="photos">
      <v-row dense>
        <v-col
          v-for="photo in photos"
          :key="photo.id"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            :src="resolveThumbnailPhotoById(photo)"
            aspect-ratio="1"
            @click="overlay = !overlay"
          ></v-img>
          <v-overlay :value="overlay" :opacity="0.46">
            <div class="d-flex justify-end">
              <v-btn icon @click="overlay = false">
                <v-icon color="white">{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </div>

            <!-- <v-card max-height="500" max-width="700"> -->
            <v-carousel :continuous="false" :cycle="false" :show-arrows="true">
              <v-carousel-item v-for="(photo, i) in photos" :key="i" eager>
                <v-img
                  :src="resolveLargePhotoById(photo)"
                  height="100%"
                  eager
                  cover
                ></v-img>
              </v-carousel-item>
            </v-carousel>
            <!-- </v-card> -->
          </v-overlay>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <div class="d-flex align-center justify-center">
        <span class="text-xl">No photos taken</span>
      </div>
    </v-card-text>
  </v-card>
</template>
