<script>
  import { mdiEyeOutline } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";

  import { usePagination } from "@/composables/usePagination";
  import { formatCurrency } from "@/helpers/filter";

  export default defineComponent({
    props: {
      loggedItems: {
        type: Array,
        required: true
      }
    },

    setup() {
      const itemTrackingHeaders = [
        { text: "ITEM NO.", value: "item_num", sortable: true },
        { text: "QTY", value: "item_qty", align: "right", sortable: true },
        { text: "UOM", value: "item_unit", align: "left", sortable: false }
      ];

      const { selectedNumberOfItemsPerPage, page, options } = usePagination();

      const actionMenu = ref(false);

      return {
        formatCurrency,
        itemTrackingHeaders,

        // usePagination
        selectedNumberOfItemsPerPage,
        page,
        options,

        actionMenu,
        icons: {
          mdiEyeOutline
        }
      };
    }
  });
</script>

<template>
  <div>
    <v-card flat id="invoice-list">
      <v-card-title> Logged Items </v-card-title>
      <!-- data table -->
      <v-data-table
        v-if="loggedItems"
        :headers="itemTrackingHeaders"
        :items="loggedItems"
        :options.sync="options"
        no-data-text="No items logged today"
        loading-text="Loading... Please wait"
        item-key="fulcrum_id"
        disable-sort
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <!-- item number -->
        <template #[`item.item_num`]="{ item }">
          <div class="d-flex align-center">
            <div v-if="item.item_num" class="d-flex flex-column">
              <span
                class="black--text font-weight-medium"
                style="max-width: 500px"
                >{{ item.item_desc }}</span
              >

              <small class="black--text font-weight-light"
                >#{{ item.item_num }}</small
              >
            </div>
          </div>
        </template>

        <!-- item quantities -->
        <template #[`item.item_qty`]="{ item }">
          <span class="black--text font-weight-medium">
            {{ Number.parseFloat(item.item_qty).toFixed(2) }}
          </span>
        </template>

        <!-- item unit -->
        <template #[`item.item_unit`]="{ item }">
          <span class="black--text font-weight-light">
            {{ item.item_unit }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<style></style>
