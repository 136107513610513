<script>
  import {
    mdiAccountHardHat,
    mdiClipboardPulseOutline,
    mdiCommentEyeOutline,
    mdiCommentTextOutline,
    mdiDumpTruck,
    mdiImageMultipleOutline,
    mdiWeatherSunny
  } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";

  import DailyLogContractorStats from "@/views/apps/daily-log/daily-log-view/daily-log-contractor-stats/DailyLogContractorStats.vue";
  import DailyLogDelayAlert from "@/views/apps/daily-log/daily-log-view/daily-log-delay-alert/DailyLogDelayAlert.vue";
  import DailyLogSummaryStats from "@/views/apps/daily-log/daily-log-view/daily-log-summary-stats/DailyLogSummaryStats.vue";

  export default defineComponent({
    props: {
      dailyLogData: {
        type: Object,
        required: true
      }
    },
    components: {
      DailyLogDelayAlert,
      DailyLogContractorStats,
      DailyLogSummaryStats
    },
    setup(props) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC"
      };
      const todaysDate = new Date(props.dailyLogData.idr_date).toLocaleString(
        "en-US",
        options
      );

      const menu = ref(false);

      return {
        todaysDate,
        menu,
        icons: {
          mdiWeatherSunny,
          mdiImageMultipleOutline,
          mdiClipboardPulseOutline,
          mdiAccountHardHat,
          mdiDumpTruck,
          mdiCommentEyeOutline,
          mdiCommentTextOutline
        }
      };
    }
  });
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12">
        <daily-log-delay-alert
          v-if="
            dailyLogData.delay_duration ||
            dailyLogData.delay_reason ||
            dailyLogData.delay_reason_other
          "
          :delay-duration="dailyLogData.delay_duration"
          :delay-reason="dailyLogData.delay_reason"
          :delay-reason-other="dailyLogData.delay_reason_other"
          class="my-8"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card min-height="500" flat>
          <v-card-title class="align-start mb-8">
            <span class="font-weight-semibold">
              Summary for {{ todaysDate }}
            </span>

            <v-spacer></v-spacer>

            <v-chip
              class="v-chip-light-bg primary--text font-weight-semibold"
              small
            >
              Daily View
            </v-chip>
          </v-card-title>

          <!-- Summary Stats -->
          <daily-log-summary-stats
            :weather="dailyLogData.weather"
            :temperature="dailyLogData.temperature"
            :logged-items="dailyLogData.logged_items"
            :idrPhoto="dailyLogData.idr_photo"
            :icons="icons"
          ></daily-log-summary-stats>

          <!-- Contractor Stats -->
          <daily-log-contractor-stats
            :contractor-stats="dailyLogData.contractor_stats"
            :icons="icons"
          ></daily-log-contractor-stats>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
