<script>
  import { mdiAlertOutline } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  export default defineComponent({
    props: {
      delayDuration: {
        type: String,
        required: false
      },
      delayReason: {
        type: String,
        required: false
      },
      delayReasonOther: {
        type: String,
        required: false
      }
    },
    setup() {
      const noReason = "No delay reason given.";
      return {
        noReason,
        icons: {
          mdiAlertOutline
        }
      };
    }
  });
</script>

<template>
  <v-alert
    dense
    text
    color="error"
    border="right"
    :icon="icons.mdiAlertOutline"
    transition="slide-x-transition"
    class="font-weight-medium"
  >
    A delay of {{ delayDuration }} day has occurred due to
    {{ delayReason || delayReasonOther || noReason }}.
  </v-alert>
</template>
