import {
  mdiWeatherCloudy,
  mdiWeatherPartlyCloudy,
  mdiWeatherPouring,
  mdiWeatherRainy,
  mdiWeatherSnowy,
  mdiWeatherSunny
} from "@mdi/js";
export const weatherIcon = (weather) => {
  switch (weather) {
    case "Clear":
      return mdiWeatherSunny;
    case "Cloudy":
      return mdiWeatherPartlyCloudy;
    case "Overcast":
      return mdiWeatherCloudy;
    case "Light Rain":
      return mdiWeatherRainy;
    case "Heavy Rain":
      return mdiWeatherPouring;
    case "Snow":
      return mdiWeatherSnowy;
  }
};
