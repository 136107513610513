import { ref } from "@vue/composition-api";

export function usePagination() {
  const selectedNumberOfItemsPerPage = ref(50);
  const page = ref(1);
  const options = ref({
    page: page,
    itemsPerPage: selectedNumberOfItemsPerPage
  });

  return {
    selectedNumberOfItemsPerPage,
    page,
    options
  };
}
