<script>
  import { defineComponent } from "@vue/composition-api";

  import { weatherIcon } from "@/helpers/iconTypes.js";

  export default defineComponent({
    props: {
      weather: {
        type: String,
        required: true
      },
      temperature: {
        type: String,
        required: true
      },
      loggedItems: {
        type: Array,
        required: true
      },
      idrPhoto: {
        type: Array,
        required: false
      },
      icons: {
        type: Object,
        required: true
      }
    },
    setup() {
      return {
        weatherIcon
      };
    }
  });
</script>
<template>
  <v-card-text class="align-start mb-8">
    <div class="d-flex justify-space-around">
      <!-- Weather -->
      <div class="d-flex align-center">
        <v-avatar size="44" color="info" rounded class="elevation-1">
          <v-icon dark color="white" size="30">
            {{ weatherIcon(weather) }}
          </v-icon>
        </v-avatar>

        <div class="ms-3">
          <p class="text-xs mb-0">{{ weather }}</p>
          <h3 class="text-xl font-weight-bold">{{ temperature }}&deg;F</h3>
        </div>
      </div>
      <!-- Items -->
      <div class="d-flex align-center">
        <v-avatar size="44" color="warning" rounded class="elevation-1">
          <v-icon dark color="white" size="30">
            {{ icons.mdiClipboardPulseOutline }}
          </v-icon>
        </v-avatar>

        <div class="ms-3">
          <p class="text-xs mb-0">Items</p>
          <h3 class="text-xl font-weight-bold">
            {{ loggedItems.length }}
          </h3>
        </div>
      </div>
      <!-- Photos -->
      <div class="d-flex align-center">
        <v-avatar size="44" color="success" rounded class="elevation-1">
          <v-icon dark color="white" size="30">
            {{ icons.mdiImageMultipleOutline }}
          </v-icon>
        </v-avatar>

        <div class="ms-3">
          <p class="text-xs mb-0">Photos</p>
          <h3 v-if="idrPhoto !== null" class="text-xl font-weight-bold">
            {{ idrPhoto.length }}
          </h3>
          <h3 v-else class="text-xl font-weight-bold">0</h3>
        </div>
      </div>
    </div>
  </v-card-text>
</template>
