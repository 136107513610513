<script>
  import { defineComponent } from "vue-demi";

  export default defineComponent({
    props: {
      equipmentItems: {
        type: Array,
        required: false
      }
    },

    setup() {
      return {};
    }
  });
</script>
<template>
  <v-card flat>
    <v-card-title> Equipment On Site Today </v-card-title>
    <v-card-text>
      <v-data-iterator
        :items="equipmentItems"
        item-key="contractor_name"
        :items-per-page="9"
        single-expand
        hide-default-footer
      >
        <template v-slot:default="{ items }">
          <v-row>
            <v-col
              v-for="item in items"
              :key="item.contractor_name"
              cols="12"
              sm="12"
              lg="4"
            >
              <v-card outlined elevation="5">
                <v-card-title
                  ><h6>{{ item.contractor_name }}</h6></v-card-title
                >
                <v-divider></v-divider>
                <v-list>
                  <v-list-item v-if="item.bucket_truck_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Bucket Trucks:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.bucket_truck_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.concrete_truck_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Concrete Trucks:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.concrete_truck_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.dump_truck_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Dump Trucks:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.dump_truck_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.line_truck_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Line Trucks:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.line_truck_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.service_truck_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Service Trucks:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.service_truck_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.tma_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >TMAs:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      right
                      >{{ item.tma_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.arrow_board_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Arrow Boards:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.arrow_board_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.backhoe_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Backhoes:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.backhoe_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.boring_machine_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Boring Machines:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.boring_machine_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.crane_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Cranes:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.crane_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.compressor_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Compressors:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.compressor_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.road_saw_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Road Saws:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.road_saw_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.tamp_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Tamps:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.tamp_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.tractor_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Tractors:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.tractor_qty }}</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item v-if="item.trencher_qty > 0">
                    <v-list-item-content class="text--secondary"
                      >Trenchers:</v-list-item-content
                    >
                    <v-list-item-content
                      class="d-flex justify-space-around align-end font-weight-medium"
                      >{{ item.trencher_qty }}</v-list-item-content
                    >
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>
  </v-card>
</template>
