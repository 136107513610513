var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","id":"invoice-list"}},[_c('v-card-title',[_vm._v(" Logged Items ")]),(_vm.loggedItems)?_c('v-data-table',{attrs:{"headers":_vm.itemTrackingHeaders,"items":_vm.loggedItems,"options":_vm.options,"no-data-text":"No items logged today","loading-text":"Loading... Please wait","item-key":"fulcrum_id","disable-sort":"","footer-props":{
        itemsPerPageOptions: [5, 10, 25, 50]
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.item_num",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.item_num)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"black--text font-weight-medium",staticStyle:{"max-width":"500px"}},[_vm._v(_vm._s(item.item_desc))]),_c('small',{staticClass:"black--text font-weight-light"},[_vm._v("#"+_vm._s(item.item_num))])]):_vm._e()])]}},{key:"item.item_qty",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"black--text font-weight-medium"},[_vm._v(" "+_vm._s(Number.parseFloat(item.item_qty).toFixed(2))+" ")])]}},{key:"item.item_unit",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"black--text font-weight-light"},[_vm._v(" "+_vm._s(item.item_unit)+" ")])]}}],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }