<script>
  import { mdiChevronRight } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";

  export default defineComponent({
    name: "Breadcrumbs",
    props: {
      items: {
        type: Array,
        required: true
      },
      activeLink: {
        type: Object,
        required: false
      }
    },
    setup(props) {
      const masterItemsList = ref([]);
      masterItemsList.value = props.activeLink
        ? props.items.concat(props.activeLink)
        : props.items;

      return {
        masterItemsList,
        icons: {
          mdiChevronRight
        }
      };
    }
  });
</script>

<template>
  <v-breadcrumbs large :items="masterItemsList">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
        <v-icon v-if="item.icon" left>{{ item.icon }}</v-icon>
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <template v-slot:divider>
      <v-icon>{{ icons.mdiChevronRight }}</v-icon>
    </template>
  </v-breadcrumbs>
</template>
